import React, { Component } from 'react';
import { Header } from '../layout/Header';
import { Helmet } from "react-helmet";
export class Chancellors_message extends Component {
    render() {

        return (       
            <div className="site-main">  
             <Helmet>
             <title>Chancellor&#039;s message - Rostov State Medical University, Russia</title>
<meta name="description" content="Greeting speech of the Rector of Rostov state medical university. RostSMU is the largest educational, scientific and medical center of Southern Russia." />
<link rel="canonical" href="https://rostgmu.net/chancellors-message/" />      
</Helmet>  
<section className="hero-section overlay bg-cover">
  <div className="container">
    <div className="hero-slider">   
      <div className="hero-slider-item">
        <div className="row">
          <div className="col-md-8">
            <h1 className="text-white" data-animation-out="fadeOutRight" data-delay-out="5" data-duration-in=".3" data-animation-in="fadeInLeft" data-delay-in=".1">Chancellor’s message
</h1>
           
        </div>
      </div>
     
    </div>
  </div>
  </div>
</section>

<section className="section">
  <div className="container">

 
  <div className="row list-items align-items-center">        
      <div className="col-md-5 order-2 order-md-1">
<div className='bg-green text-center'>
     <h3>Chancellor’s message</h3>
      <img src="images/Olesya.jpeg" className="img-fluid" alt="Chancellor’s message" />
		  
      <h3><strong>Acting Rector of University</strong></h3>
<h6><strong>Candidate of Medical Sciences, Surgeon., </strong><br/><strong>Olesya Borisovna Starzhinskaya</strong></h6>
	  </div>   
          </div>
          <div className="col-md-7 order-2 order-md-1">


	
	
	 <p><strong>Greeting speech of the Acting Rector of RostSMU!</strong></p> 
      <p>Rostov State Medical University is the leading Higher Educational Institution of the Rostov region on training medical specialists. It is also the largest educational, scientific and medical center in Southern Russia and is one of the oldest universities in our country. We are proud to offer our medical, dental, pharmaceutical, and nursing education programs.</p>
<p>I hope that the Internet resources of Rostov State Medical University will help you get all the necessary information about our University. Here you can find everything about the University structure, its faculties and departments, scientific and research projects, and other kinds of reference information. On the website, special attention is paid to the applicants who can learn about the specialties, admission rules, training, and accommodation conditions of Rostov State Medical University.</p>
<p>University success is based on a blend of glorious traditions and actual innovative approaches multiplied by the great potential of human resources. We are proud to be a unique educational, scientific and medical complex that graduates, certifies, and provides lifelong professional education for physicians and other healthcare workers; that contributes significantly to domestic and global medicine. The University is closely linked to the fundamental and applied research, highly efficient medical treatment, and propaganda of medical and pharmaceutical achievements.</p>
<p>Choosing a university is one of the most important and challenging decisions of your life. Education in Rostov State Medical University is highly-rated, we are in the list of top medical universities in Russian federation. Students consider RostSMU as a desirable place to study and acknowledge our high-grade teaching.</p>

<p>I wish you every success in your study in our university and hope that it will be an exciting, challenging and diverse place to be!</p>

<div className='text-right'>
<h3><strong>Acting Rector of University</strong></h3>
<h6><strong>Candidate of Medical Sciences, Surgeon., </strong><br/><strong>Olesya Borisovna Starzhinskaya</strong></h6>
</div>

    
</div>
 </div>

    
      


       </div>
      </section>

  </div>


 )
}

}

export default Chancellors_message;