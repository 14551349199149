import React, { Component } from 'react';
import { Header } from '../layout/Header';
import { Helmet } from "react-helmet";
export class Admission_procedure_for_foreign_applicants extends Component {
  render() {

    return (
      <div className="site-main">
        <Helmet>
          <title>Admission Procedure for Rostov State Medical University</title>
          <meta name="description" content="International students wishing to study at RostSMU have to undergo the admission procedure, which is quite simple. After submitting the application form our official representative will contact you." />
          <link rel="canonical" href="https://rostgmu.net/admission-procedure-for-foreign-applicants/" />
        </Helmet>

        <section className="hero-section overlay bg-cover">
          <div className="container">
            <div className="hero-slider">
              <div className="hero-slider-item">
                <div className="row">
                  <div className="col-md-8">
                    <h1 className="text-white" data-animation-out="fadeOutRight" data-delay-out="5" data-duration-in=".3" data-animation-in="fadeInLeft" data-delay-in=".1">Admission Procedure</h1>

                  </div>
                </div>

              </div>
            </div>
          </div>
        </section>

        <section className="section">
          <div className="container">
            <div className="row list-items">
              <div className="col-md-12 order-2 order-md-1">
                <img src='images/admission-procedure-for-foreign-applicants.webp' className='img-fluid' alt='' />

                <h1>Minimum Qualification for Admission (for foreign students):</h1>
                <p>Rostov state medical university admission dates for International Students: One intake in a year for all faculties.</p>
                <ul>
                <li>For Russian Medium Faculties admissions are open from June 20 - July 22.</li>
                <li> For English Medium General Medicine Faculty admissions are open from June 20 to October 10.</li>
                <li>For Preparatory Faculty admissions are open from June 20 to November 30.</li>
</ul>

                <h3>Admissions to <a href="https://rostovstatemedicaluniversity.com/">Rostov state medical university</a> are strictly based on a merit basis; </h3>
                <ul>
                  <li>He/she shall complete the age of 17 years on or before the 31st of December, of the year of admission to the MBBS course (General Medicine).</li>
                  <li>Successful completion of higher secondary school. </li>
                  <li>The minimum requirement is an average of 65-70% marks in the higher secondary school including English.</li>
                  <li>Results of NEET (only for candidates from India)</li>
                  <li>Guarantee for the fulfillment of payment conditions.</li>
                  <li>Admission to the first year of training in 2025 for all specialties of higher education is carried out at the Rostov State Medical University by the results of two entrance examinations, including Chemistry and Biology.</li>
                </ul>

                <h3>Required documents for admission at Rostov State Medical University, Russia</h3>
                <p>Pre-admission documents which we need are written given below: (Need scan copies only)</p>
                <h3>Step 1. APPLICATION AND REGISTRATION</h3>
                <p>The application form can be obtained from the website or from our official representative. Admission will be confirmed only after screening of the documents/application form of the candidate and by the approval of the admission committee of RostSMU.</p>
                <ul>
                  <li>Online application form through our website or student can ask our representative for the same. </li>
                  <li>Mark sheet of secondary school certificate (Where we can able to see English, Chemistry, Physics, Biology marks/grades for Indian students and for International students only English, Chemistry and Biology grades/marks).</li>
                  <li>A secondary certificate stating the date of birth.</li>
                  <li>A recent HIV test report. (Name must be as per passport, date of birth, and passport number)</li>
                  <li>A valid passport with a minimum duration of 2 years.</li>
                  <li>The age limit for applicants to higher educational establishments is 17- 28 years. For postgraduate candidates-35 years. For Doctoral Candidates-45 years.</li>
                  <li>Your contact information: postal address, phone numbers, and email.</li>
                </ul>
                <p><strong>Note please:</strong> Admissions will be rejected through unauthorized persons or agents.</p>
                <h3>Step 2. ADMISSION LETTER</h3>
                <p>After due consideration (usually takes from 5 to 7 working days) if your application gets a positive response we will send you a provisional admission letter signed by the Dean of Foreign Students confirming the acceptance of documents for the admission and invitation letter.</p>
                <h3>Step 3. INVITATION LETTER</h3>
                <p>You should confirm in writing your decision to enter RostSMU. At that, we will start to prepare all the necessary documents to issue an official Invitation Letter via Federal Migration Service Department. The procedure takes around 18-25 days, so your early confirmation is advisable. Your Invitation Letter we will send to you by electronic mail or you can collect it from our representative. </p>
                <h3>Step 4. VISA, ENTRY INTO RUSSIA AND REGISTRATION IN THE UNIVERSITY</h3>
                <p>As soon as you get the original Invitation Letter you should turn to the Consulate Department of the Russian Embassy in your country and apply for a <u>Russian study visa</u>. Aspirants can take the help of our representative for all documentation. </p>
                <p><strong>IMPORTANT:</strong> For other countries, we will send all required documents list according to the Russian Embassy in their native country, for Indian students our official representative will do all work from admission, invitation, visa, ticket, ministry apostille, etc. </p>

                <h4>Student’s must bring the following documents in order to enter the territory of the Russian Federation and to be admitted to the university:</h4>
                <ul>
                  <li>Passport (valid for not less than 2 years);</li>
                  <li>Student entry visa;</li>
                  <li>Immigration Card (supplied during the flight or at the airport; it must be completed by the student and stamped by customs in the airport right after arrival in Russia)</li>
                  <li>Negative AIDS test result, Medical fitness certificate, Chest X-ray.</li>
                  <li>15 passport size (3.5-4.5mm) colored photographs in matt finish.</li>
                </ul>
                <h3>For candidates obtaining admission to the foundation course or the first year of studies:</h3>
                <ul>
                  <li>Original of complete higher secondary education certificate (including mark sheet/academic transcript), that should be legalized/apostilled (i.e. stamped by the Ministry of External Affairs (or Ministry of Education) and the Embassy/General Consulate of the Russian Federation in the country where the student obtained the certificate).</li>
                  <li>2 copies of legalized/apostilled completed higher secondary education certificate (including mark sheet/academic transcript) notarized at the Embassy/ General Consulate of the Russian Federation and translated into the Russian language; the translation should be certified by a notary from the Embassy/General Consulate of the Russian Federation in your country and attached to the copies. All pages of the copies should be numbered, stamped, and officially sealed.</li>
                </ul>
                <h3>For candidates obtaining admission to the Master’s degree, Postgraduate course, or PhD</h3>
                <ul>
                  <li>Original of higher education degree (including mark sheet/academic transcript for all academic years with subjects, marks and hours for each subject listed) that should be legalized/apostilled, (i.e. stamped by the Ministry of External Affairs (or Ministry of Education) and the Embassy/General Consulate of the Russian Federation in the country where the student obtained the certificate).</li>
                  <li>2 copies of legalized/apostilled higher education degree (including mark sheet/academic transcript) notarized at the Embassy/ General Consulate of the Russian Federation and translated into the Russian language; the translation should be certified by a notary from the Embassy/General Consulate of the Russian Federation in your country and attached to the copies. All pages of the copies should be numbered, stamped, and officially sealed.</li>
                </ul>
                <h3>Thus, each legalized document should have 2 or more stamps:</h3>
                <ul>
                  <li>Legalization stamp of the Ministry of External Affairs of the country that issued your educational documents</li>
                  <li>Legalization stamp of the Embassy/ General Consulate of the Russian Federation in the country that issued your educational documents.</li>
                </ul>
                <p><strong>IMPORTANT:</strong> These documents must be with each student from any country of the world before entering Russia and to RostSMU, without these sets of original documents university is unable to accept your admission.</p>
                <h3>Receiving of Students in Russia:</h3>
                <p>All incoming foreign students will be received at the place of arrival in Russia by the authorized representative of the University only. </p>



              </div>
            </div>

            <div className="row list-items align-items-center">
              <div className="col-md-12 order-2 order-md-1 text-center">
                <div className="bg-green">
                  <h3><strong>Admissions open for the academic session: 2025-26</strong></h3>
                  <p><strong>Admission Cell Contact: </strong><a href="mailto:rostsmu@gmail.com"><strong>rostsmu@gmail.com</strong></a></p>
                  <p><strong>MBBS Admission in Russia | Get direct admission in Rostov state medical university, Russia</strong></p>
                  <p><strong>MBBS in Russia 2025, Study Medicine in Russia </strong></p>
                </div>
              </div>

            </div>


          </div>
        </section>


      </div>


    )
  }

}

export default Admission_procedure_for_foreign_applicants;