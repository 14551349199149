import React, { Component } from 'react';
import { Header } from '../layout/Header';
import { Helmet } from "react-helmet";
export class Study_medicine_in_russia extends Component {
  render() {

    return (
      <div className="site-main">
        <Helmet>
          <title> Study Medicine in Russia - A little about Russia- Medical study in Russia, Rostov Medical University</title>
          <meta name="description" content="Medical study in Russia has gained high popularity among foreign students around the world. Every year number of foreign students are increasing for studying medicine in Russia. Official website of Rostov State Medical University, Russia Rostov state medical university website for foreign students" />
          <link rel="canonical" href="https://rostgmu.net/study-medicine-in-russia/" />
        </Helmet>

        <section className="hero-section overlay bg-cover">
          <div className="container">
            <div className="hero-slider">
              <div className="hero-slider-item">
                <div className="row">
                  <div className="col-md-8">
                    <h1 className="text-white" data-animation-out="fadeOutRight" data-delay-out="5" data-duration-in=".3" data-animation-in="fadeInLeft" data-delay-in=".1">A little about Russia</h1>

                  </div>
                </div>

              </div>
            </div>
          </div>
        </section>

        <section className="section">
          <div className="container">
            <div className="row list-items">
              <div className="col-md-12 order-2 order-md-1">

                <h1><strong>A little about Russia  <a href="https://rostgmu.net/">(Rostov State Medical University)</a></strong></h1>
                <p>Russia is the largest country in the world and is situated on the two continents of the world: Europe and Asia. There are all climatic zones except tropical ones. Russian Federation has a large population. They speak different languages, but Russian is the most spoken one. There are 53 medical academies, universities, and institutions. All of them are recognized by the World Health Organization and the Medical Councils of foreign countries.</p>
                <p>Medical Doctor is the most respectable career in the world. Indians, who graduated from Russian Medical Institutions, are working in India in Government jobs and in good private hospitals. A lot of foreign students, who graduated from Russian Medical Institutions, are working in foreign countries like the USA, Canada, England, etc.</p>
                <h3><strong>Medical science about Russia</strong></h3>
                <p>Russia is a land of great scientific achievements in medicine. There is a lot of prominent and distinguished Russian physicians, founders of new schools, and scientific trends.</p>
                <p>Russian medical science has contributed much to the world of medicine of the XIX century. It’s due to a constellation of scientists both in theoretical and practical fields of medicine: physiologists I.M. Sechenov and I.R Pavlov, microbiologists I.I. Mechnikov, physicians S.R Botkin and G.A. Zakharjin, surgeons N.I. Pirogov and N.V. Sklifosovsky, ophthalmologist V.R Filatov. The names of our contemporaries such as pathologists I.V. Davidovsky, ophthalmologist S.N. Fedorov, orthopedist G.A. Ilizarov, neurosurgeon N.N. Burdenko is well known all over the world.</p>
                <p><a href="https://rostgmu.net/">Rostov state medical university</a> is the first choice university of foreign students since USSR time.</p>
                <h3><strong>One of the oldest &amp; reputed Medical Government University of Russia.</strong></h3>




              </div>
            </div>

            <div className="row list-items align-items-center">
              <div className="col-md-6 order-2 order-md-1">
                <h3>Rostov State Medical University</h3>
                <p><iframe title="Rostov State Medical University | Russia | Official" width="100%" height="360" src="https://www.youtube.com/embed/aWRUegjs4N8?start=232&#038;feature=oembed" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe></p>
              </div>
              <div className="col-md-6 order-2 order-md-1">
                <h3>Webinar session of 2025 Admissions:-</h3>
                <p><iframe title="Rostov State Medical University | Admission Open For 2025 | Get Direct Admission From Our Officials" width="100%" height="360" src="https://www.youtube.com/embed/EqdUuJZG2Fo?feature=oembed" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe></p>
              </div>
            </div>

            <div className="row list-items align-items-center">
              <div className="col-md-6 order-2 order-md-1">
                <h3>Choose one of the oldest &amp; best university for you:-</h3>
                <p><iframe title="Rostov State Medical University | 130 Yrs Of Excellence | Choose The Best For You | MBBS In Russia" width="100%" height="360" src="https://www.youtube.com/embed/d1C_DFa5v7M?feature=oembed" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe></p>
              </div>
              <div className="col-md-6 order-2 order-md-1">
                <div className='bg-green text-center'>
                  <h3>study medicine in Russia for foreign students <br /> study medicine Rostov state medical university<br /> Russia &#8211; Medical study in Russia &#8211; study medical MBBS in Russia &#8211; MBBS in Russia- Study MBBS in RostSMU Rostov &#8211; Medical education in Russia Rostov</h3>
                </div>
              </div>
            </div>


          </div>
        </section>


      </div>


    )
  }

}

export default Study_medicine_in_russia;