import React, { Component } from 'react';
import { Navbar, Container, Row, Col, Button, Nav, NavDropdown } from 'react-bootstrap';
import { createBrowserHistory } from 'history';
import { HashRouter as Router, Route } from 'react-router-dom';

export class Menu extends Component {
  render() {
    return (
      <Navbar expand="lg" className='main-navbar navbar navbar-expand-lg navbar-light main-nav' id="mobile-menu">
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav" className="main-menu menu-mobile">

          <Nav>
            <ul className="navbar-nav mr-auto">
              <li className="nav-item"><a href={process.env.PUBLIC_URL + '/rostov-state-medical-university-history'}>RSMU HISTORY <span class="sr-only">(current)</span></a></li>
              <li className="nav-item"><a href={process.env.PUBLIC_URL + '/study-medicine-in-russia-rostov-on-don-city'}>ROSTOV ON DON CITY</a></li>
              <li className="nav-item"><a href={process.env.PUBLIC_URL + '/rostov-state-medical-university-faculties-and-fees'}>RSMU FACULTIES &amp; FEES</a></li>
              <li className="nav-item active-red"><a href={process.env.PUBLIC_URL + '/representative-of-foreign-students'} ><span className="blink-text">OFFICIAL REPRESENTATIVE</span></a></li>
              <li className="nav-item"><a href={process.env.PUBLIC_URL + '/admission-procedure-for-foreign-applicants'}>ADMISSION PROCEDURE</a></li>
              <li className="nav-item active-red"><a href={process.env.PUBLIC_URL + '/apply-online-for-study-medicine-in-rostov-state-medical-university-russia'}>APPLY NOW</a></li>

            </ul>

          </Nav>
        </Navbar.Collapse>
      </Navbar>

    )
  }
}
export default Menu;