import React, { Component } from 'react'

export class UpcomingNewsRostov extends Component {
    render() {
        return (
            <div className="site-main">
                <section className="hero-section overlay bg-cover">
                    <div className="container">
                        <div className="hero-slider">
                            <div className="hero-slider-item">
                                <div className="row">
                                    <div className="col-md-8">
                                        <h1 className="text-white" data-animation-out="fadeOutRight" data-delay-out="5" data-duration-in=".3" data-animation-in="fadeInLeft" data-delay-in=".1">Upcoming News</h1>

                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </section>

                <section className="section">
                    <div className="container">
                        <div className="row list-items">
                            <div className="col-md-12 order-2 order-md-1">
                                <h6 className='text-red'>Date: 05.09.2025</h6>
                                <h1><strong>Rostov State Medical University entered the sixth edition of the Moscow International Ranking "Three Missions of the University".</strong></h1>

                                <p>🎓In total, the ranking in 2025 includes</p>
                                <p>* 1800 universities from 103 countries,</p>
                                <p>* including 146 Russian educational institutions.</p>

                                <h3><strong>🏆Rostov State Medical University took 10th place among medical universities in Russia.</strong></h3>

                                <p>✔This is a fundamentally new academic ranking, which for the first time evaluates all three key missions of the university:</p>
                                <p>* education,</p>
                                <p>* science,</p>
                                <p>* interaction with society.</p>

                                <p>📌The Moscow International Ranking "Three Missions of the University" was published by the Russian Union of Rectors and prepared by the Association of Ranking Compilers with the information assistance of the Ministry of Science and Higher Education of the Russian Federation.</p>


                            </div>
                        </div>



                    </div>
                </section>
            </div>
        )
    }
}

export default UpcomingNewsRostov
