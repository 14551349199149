import React, { Component, Fragment } from 'react'
import { Link } from "react-router-dom";
import wht from '../../assets/images/wht.webp';
import phone from '../../assets/images/phone.webp';
import apply from '../../assets/images/app.webp';




class Socialmedia extends Component {
  render() {
    return (
      <Fragment>


        <div className="social1">
          <ul>
            <li><a href="https://wa.me/+79515173719" target="_blank"><img src={wht} className="img-fluid" alt="Study MBBS in Russia,  MBBS in Russia" /></a></li>
            <li><a href="tel:+79515173719" target="_blank"><img src={phone} className="img-fluid" alt="Study MBBS in Russia,  MBBS in Russia" /></a></li>
          </ul>
        </div>



        <div className="app">
          <ul>
            <li><a href={process.env.PUBLIC_URL + '/apply-online-for-study-medicine-in-rostov-state-medical-university-russia'} ><img src={apply} className="img-fluid" alt="Online application form - Rostov State Medical University" /></a></li>
          </ul>
        </div>


      </Fragment>
    )
  }
}

export default Socialmedia
