import React, { Component } from 'react';
import { Header } from '../layout/Header';
import { Helmet } from "react-helmet";





export class Apply_online_for_study_medicine_in_rostov_state_medical_university_russia extends Component {
    render() {

        return (
            <div className="site-main">
                <Helmet>
                    <title>Online application form - Rostov State Medical University</title>
                    <meta name="description" content="Rostov State Medical University is a prominent Institution in the field of Medicine in the region of Southern Russia. Duration 6 Years. Book your seat for 2025." />
                    <link rel="canonical" href="https://rostgmu.net/apply-online-for-study-medicine-in-rostov-state-medical-university-russia/" />
                </Helmet>

                <section className="section">
                    <div className="container">
                        <div className="row list-items">
                            <div className="col-md-12 order-2 order-md-1 bg-light-blue">
                                <div className="spacing-10 ttm-bgcolor-grey ttm-bg ttm-col-bgcolor-yes ttm-right-span">
                                    <div className="ttm-col-wrapper-bg-layer ttm-bg-layer">

                                        <form id="ttm-quote-form" className="row ttm-quote-form clearfix" name="f1" method="post" action="https://app.todesign.in/react/rostgmu.net/apply.php">
                                            <div className="col-sm-6 col-md-6">
                                                <div className="form-group">
                                                    <label>First Name</label>
                                                    <input name="fname" id="fname" type="text" className="form-control bg-white" placeholder="First Name*" required="required" />
                                                </div>
                                            </div>
                                            <div className="col-sm-6 col-md-6">
                                                <div className="form-group">
                                                    <label>Last Name</label>
                                                    <input name="lname" id="lname" type="text" placeholder="Last Name" required="required" className="form-control bg-white" />
                                                </div>
                                            </div>
                                            <div className="col-sm-6 col-md-6">
                                                <div className="form-group">
                                                    <label>Phone Number</label>
                                                    <input name="phone" id="phone" type="text" placeholder="Phone Number*" required="required" className="form-control bg-white" />
                                                </div>
                                            </div>
                                            <div className="col-sm-6 col-md-6">
                                                <div className="form-group">
                                                    <label>Email Address</label>
                                                    <input name="email" id="email" type="email" placeholder="Email Address*" required="required" className="form-control bg-white" />
                                                </div>
                                            </div>

                                            <div className="col-sm-6 col-md-6">
                                                <div className="form-group">
                                                    <label>Date of Birth</label>
                                                    <input name="dob" id="dob" type="date" placeholder="Date of Birth*" required="required" className="form-control bg-white" />
                                                </div>
                                            </div>

                                            <div className="col-sm-6 col-md-6">
                                                <div className="form-group">
                                                    <label>Nationality</label>
                                                    <input name="nationality" id="nationality" type="text" placeholder="Nationality*" required="required" className="form-control bg-white" />
                                                </div>
                                            </div>

                                            <div className="col-sm-4 col-md-4">
                                                <div className="form-group">
                                                    <label>Passport Number</label>
                                                    <input name="passport" id="passport" type="text" placeholder="Passport Number*" required="required" className="form-control bg-white" />
                                                </div>
                                            </div>

                                            <div className="col-sm-4 col-md-4">
                                                <div className="form-group">
                                                    <label>Passport Issue on</label>
                                                    <input name="pissue" id="pissue" type="text" placeholder="Passport Issue on*" required="required" className="form-control bg-white" />
                                                </div>
                                            </div>

                                            <div className="col-sm-4 col-md-4">
                                                <div className="form-group">
                                                    <label>Passport Valid To</label>
                                                    <input name="pvalid" id="pvalid" type="text" placeholder="Passport Valid To*" required="required" className="form-control bg-white" />
                                                </div>
                                            </div>

                                            <div className="col-sm-6 col-md-6">
                                                <div className="form-group">
                                                    <label>RSMU Faculties </label>
                                                    <select id="faculties" name="faculties" placeholder="RSMU Faculties*" type="text" required="required" className="form-control bg-white"
                                                    >
                                                        <option value="disabled selected" className="form-group">Select the one</option>
                                                        <option value="Medicine (MBBS)" >Medicine (MBBS)</option>
                                                        <option value="Dentistry (BDS)" >Dentistry (BDS)</option>
                                                        <option value="Pharmacy" >Pharmacy</option>
                                                        <option value="Pediatrics" >Pediatrics</option>
                                                        <option value="Preparatory Course" >Preparatory Course</option>
                                                        <option value="Post Graduation" >Post Graduation</option>
                                                    </select>
                                                </div>
                                            </div>

                                            <div className="col-sm-6 col-md-6">
                                                <div className="form-group">
                                                    <label>Medium of Instruction</label>
                                                    <select id="medium" name="medium" placeholder="Medium of Instruction*" type="text" required="required" className="form-control bg-white"   >
                                                        <option value="disabled selected" className="form-group">Select the one</option>
                                                        <option value="English Medium" >English Medium</option>
                                                        <option value="Russian Medium" >Russian Medium</option>
                                                    </select>
                                                </div>
                                            </div>




                                            <div className="col-sm-6 col-md-6">
                                                <div className="form-group">
                                                    <label>Address</label>
                                                    <textarea name="address" id="address" rows={5} placeholder="Address..." required="required" className="form-control bg-white" />
                                                </div>
                                            </div>

                                            <div className="col-sm-6 col-md-6">
                                                <div className="form-group">
                                                    <label>Education Background</label>
                                                    <textarea name="education" id="education" rows={5} placeholder="Education Background..." required="required" className="form-control bg-white" />
                                                </div>
                                            </div>


                                            <div className="col-md-12">
                                                <div className="text-left">
                                                    <button type="submit" id="submit" name="submit" value="Submit" className="mt-3 ttm-btn ttm-btn-size-md ttm-bgcolor-skincolor" >
                                                        Submit
                                                    </button>
                                                </div>
                                            </div>
                                        </form>



                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>


            </div>


        )
    }

}

export default Apply_online_for_study_medicine_in_rostov_state_medical_university_russia;