import React, { Component } from 'react'

export class NewBlock extends Component {
    render() {

        return (

            <section className="section-pb">
                 <div className="container">
            <div className="row align-items-center">

<div className="col-sm-5 ">
<div className="bg-black">
<img src="images/a1.jfif" className="img-fluid rounded" alt="ROSTOV STATE MEDICAL UNIVERSITY" />
</div>
</div>

<div className="col-sm-7 red">

<h3>17 medical universities of the Ministry of Health of Russia entered the top 20 best in the country according to the RAEX rating.</h3>
<p>The rating assessed universities by the quality of education, scientific activity, and contribution to society, based on data from the Ministry of Education and Science, publication activity, and mentions in the media and social networks.</p>
<p>Among the participants in the field of medicine, <strong>ROSTOV STATE MEDICAL UNIVERSITY</strong> got (13th place) and 53,46 points. </p>

</div>

</div>
</div>
</section>

        )
    }
}
export default NewBlock;