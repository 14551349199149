import React, { Component } from 'react'

export class Facebook extends Component {
    render() {

        return (

            <ul>
                <marquee height="200" scrollamount="2" direction="up" onMouseOver="stop()" onMouseOut="start();">


                    <li><iframe src="https://www.facebook.com/plugins/post.php?href=https%3A%2F%2Fwww.facebook.com%2FRostovstatemedicaluniversityrussia%2Fposts%2Fpfbid0VXDQAU8vyZ2oHrJnYj3SL9PL4mhPgFwrspxx7iP3epjDKQwRLRtm8yy6Vks1dkB5l&show_text=true&width=500" width="100%" height="400" scrolling="no" frameborder="0" allowfullscreen="true" allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"></iframe></li>

                </marquee>
            </ul>


        )
    }
}
export default Facebook;