import React, { Component } from 'react';
import { Header } from '../layout/Header';
import { Helmet } from "react-helmet";
export class Rostov_state_medical_university extends Component {
    render() {

        return (       
            <div className="site-main">  
          <Helmet>
 <title>Rostov state medical university, Russia 100 yrs Old Medical University</title>
<meta name="description" content="Rostov State Medical University is a prominent Institution in the field of Medicine in the region of Southern Russia." />
<link rel="canonical" href="https://rostgmu.net/rostov-state-medical-university/" />
      </Helmet>   
     
<section className="hero-section overlay bg-cover">
  <div className="container">
    <div className="hero-slider">   
      <div className="hero-slider-item">
        <div className="row">
          <div className="col-md-8">
            <h1 className="text-white" data-animation-out="fadeOutRight" data-delay-out="5" data-duration-in=".3" data-animation-in="fadeInLeft" data-delay-in=".1">About University</h1>
           
        </div>
      </div>
     
    </div>
  </div>
  </div>
</section>

<section className="section">
  <div className="container">
    <div className="row list-items align-items-center">   
    <div className="col-md-12"><h2>Rostov State Medical University</h2>   </div>
         
      <div className="col-md-4">
     <p><iframe width="100%" height="315" src="https://www.youtube.com/embed/nKnV8w6Hswo" title="Rostov State Medical University | Russia | Official" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe></p>
          </div>
          <div className="col-md-4">
 <p><iframe width="100%" height="315" src="https://www.youtube.com/embed/SDNC4qdW1Es" title="Rostov State Medical University | Russia | Official" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe></p>
</div>
          <div className="col-md-4">
 <p><iframe title="Rostov State Medical University | Russia | Official" width="100%" height="315" src="https://www.youtube.com/embed/aWRUegjs4N8?start=423&#038;feature=oembed" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe></p>

</div>
          </div>

      <div className="col-md-12 order-2 order-md-1">

      <p>The Rostov state medical university (Established in 1930), is a non-profit public higher education institution located in the Rostov-on-Don city. The institution has also a branch campus in Novocherkassk.</p>
<p>Rostov state medical university (RostGMU, RostSMU, РостГМУ) offers courses and programs leading to officially recognized higher education degrees such as pre-bachelor degrees (i.e. certificates, diplomas, foundation degrees), bachelor degrees, master degrees, doctorate degrees in several areas of study. Rostov state medical university has a selective admission policy based on entrance examinations and student’s past academic records and grades. The admission rate range is 60-70% making <strong>Rostov state medical university</strong> a higher education organization a somewhat selective institution.</p>
<h2>Officially accredited by the Ministry of Health and Education of the Russian Federation.</h2>
<h3>Other Specialized or Programmatic Accreditations:</h3>
<ul>
  <li>Ministry of Health and Education, Russian Federation</li>
  <li>Canadian Medical Council</li>
  <li>Pakistan Medical and Dental Council</li>
  <li>German Science and Humanities Council</li>
  <li>National Medical Commission of India (NMC)</li>
  <li>Bangladesh Medical &amp; Dental Council</li>
  <li>Nepal Medical &amp; Dental Council</li>
  <li>Medical &amp; Dental Council of Nigeria</li>
  <li>Medical &amp; Dental Council of Ghana</li>
  <li>American Osteopathic Association</li>
  <li>Arab Countries Medical Councils</li>
  <li>African Countries Medical Councils</li>
  <li>The Medical Council of Thailand</li>
</ul>


      </div>
       </div>
       
    

  
      </section>


  </div>


 )
}

}

export default Rostov_state_medical_university;