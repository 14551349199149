import React, { Component } from 'react';
import { createBrowserHistory } from 'history';
import { BrowserRouter as Router, Routes, Link, Route } from 'react-router-dom';

import { Helmet } from "react-helmet";

import { BrowserRouter } from "react-router-dom"



import { Footer } from './components/layout/Footer';
import { Header } from './components/layout/Header';

import Home from './components/Home';
import Rostov_state_medical_university_history from './components/pages/Rostov_state_medical_university_history';
import Study_medicine_in_russia_rostov_on_don_city from './components/pages/Study_medicine_in_russia_rostov_on_don_city';
import Rostov_state_medical_university_faculties_and_fees from './components/pages/Rostov_state_medical_university_faculties_and_fees';
import Admission_procedure_for_foreign_applicants from './components/pages/Admission_procedure_for_foreign_applicants';
import Representative_of_foreign_students from './components/pages/Representative_of_foreign_students';
import Apply_online_for_study_medicine_in_rostov_state_medical_university_russia from './components/pages/Apply_online_for_study_medicine_in_rostov_state_medical_university_russia';
import Study_medicine_in_russia from './components/pages/Study_medicine_in_russia';
import Chancellors_message from './components/pages/Chancellors_message';
import Vice_chancellors_message from './components/pages/Vice_chancellors_message';
import Recognitions_links from './components/pages/Recognitions_links';
import Preparatory_faculty_on_training_foreign_students from './components/pages/Preparatory_faculty_on_training_foreign_students';
import Rostsmu_hostels_for_foreign_students from './components/pages/Rostsmu_hostels_for_foreign_students';
import Frequently_asked_questions from './components/pages/Frequently_asked_questions';
import Rostsmu_services_to_foreign_students from './components/pages/Rostsmu_services_to_foreign_students';
import About_us from './components/pages/About_us';
import Rostov_state_medical_university from './components/pages/Rostov_state_medical_university';
import Why_rostsmu from './components/pages/Why_rostsmu';
import Welcome_message_to_foreign_students from './components/pages/Welcome_message_to_foreign_students';
import Registration_open from './components/pages/Registration_open';
import Thank_you from "./components/pages/Thank_you";
import UpcomingNews from "./components/pages/UpcomingNews";
import UpcomingNewsRostov from "./components/pages/UpcomingNewsRostov";
import Gallery from './components/pages/Gallery';
import Videos from './components/pages/Videos';


const appHistory = {
  basename: process.env.PUBLIC_URL
};
const history = createBrowserHistory(appHistory);
function App() {

  return (
    <Router basename={process.env.PUBLIC_URL} history={history}>
      <Header />
        <Routes>
          <Route exact path="/" element={<Home />} />
          <Route exact path="/rostov-state-medical-university-history" element={<Rostov_state_medical_university_history />} />
        <Route exact path="/study-medicine-in-russia-rostov-on-don-city" element={<Study_medicine_in_russia_rostov_on_don_city />} />
        <Route exact path="/rostov-state-medical-university-faculties-and-fees" element={<Rostov_state_medical_university_faculties_and_fees />} />
        <Route exact path="/admission-procedure-for-foreign-applicants" element={<Admission_procedure_for_foreign_applicants />} />
        <Route exact path="/representative-of-foreign-students" element={<Representative_of_foreign_students />} />
        <Route exact path="/apply-online-for-study-medicine-in-rostov-state-medical-university-russia" element={<Apply_online_for_study_medicine_in_rostov_state_medical_university_russia />} />
        <Route exact path="/study-medicine-in-russia" element={<Study_medicine_in_russia />} />
        <Route exact path="/chancellors-message" element={<Chancellors_message />} />
        <Route exact path="/vice-chancellors-message" element={<Vice_chancellors_message />} />
        <Route exact path="/recognitions-links" element={<Recognitions_links />} />
        <Route exact path="/preparatory-faculty-on-training-foreign-students" element={<Preparatory_faculty_on_training_foreign_students />} />
        <Route exact path="/rostsmu-hostels-for-foreign-students" element={<Rostsmu_hostels_for_foreign_students />} />
        <Route exact path="/frequently-asked-questions" element={<Frequently_asked_questions />} />
        <Route exact path="/rostsmu-services-to-foreign-students" element={<Rostsmu_services_to_foreign_students />} />
        <Route exact path="/about-us" element={<About_us />} />
        <Route exact path="/rostov-state-medical-university" element={<Rostov_state_medical_university />} />
        <Route exact path="/why-rostsmu" element={<Why_rostsmu />} />
        <Route exact path="/welcome-message-to-foreign-students" element={<Welcome_message_to_foreign_students />} />
        <Route exact path="/registration-open" element={<Registration_open />} />
        <Route exact path="/thank-you" element={<Thank_you />} />
        <Route exact path="/upcoming-news" element={<UpcomingNews />} />
        <Route exact path="/upcoming-news-rostov" element={<UpcomingNewsRostov />} />
        <Route exact path="/gallery" element={<Gallery />} />
        <Route exact path="/videos" element={<Videos />} />
        </Routes>
        <Footer />
    </Router>
  );
}

export default App;
