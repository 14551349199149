import React, { Component } from 'react'

export class Logo extends Component {
    render() {

        return (   
            <div className="row align-justify align-middle row-logo">
            <div className="columns small-6 medium-4">
				<div className="logo">
					<a href={process.env.PUBLIC_URL + '/'}><img src="images/study-mbbs-in-russia.webp" className="img-fluid" alt="Study MBBS in Russia,  MBBS in Russia" /></a>
				</div>
			</div> 
             <div className="columns small-6 medium-4">
             <div className="logo">
             <a href={process.env.PUBLIC_URL + '/registration-open'}><img src="images/registration.gif" className="img-fluid" alt="Study MBBS in Russia,  MBBS in Russia" /></a>
                 </div>
                 </div>

                 <div className="columns small-12 medium-4 header-bottom">
              <div className="top-info float_right mobile-div">
                    <ul>
                       
                        <li className="single-info-box">
                            <div className="icon-holder">
                                <span className="icon-mail-alt"></span>
                            </div>
                            <div className="text-holder">
                                <p><span>Email Us</span><br /> contact@rostgmu.net</p>
                            </div>
                        </li>
                       
                       <li className="single-info-box">
                            <div className="icon-holder">
                                <span className="icon-address"></span>
                            </div>
                            <div className="text-holder">
                                <p><span>Address</span><br /> Nakhichevansky, 29</p>
                            </div>
                        </li>
                    </ul>    
                </div> 
             </div>    

               
                 </div>
     )
    }
}
export default Logo;