import React, { Component } from 'react';
import { Upcoming } from './layout/Upcoming';
import { Facebook } from './layout/Facebook';
import {Sliders} from './sliders/Sliders';
import Youtube from './sliders/Youtube';
import Clients from './sliders/Clients';
import { Helmet } from "react-helmet";
import NewBlock from './layout/NewBlock';



export class Home extends Component {
  
		render() {

					         return (       
					 
            <div className="site-main">  
	<Helmet>
<title>Study MBBS in Russia,  MBBS in Russia, Official website of Rostov State Medical University, Russia - Study Medicine in Russia in Rostov - Site of Rostov State Medical University for foreign students - Best Preparatory Course - study in Russia - Rostov State Medical University - Study MBBS in Rostov State Medical University</title>
<meta name="description" content="MBBS in Russia - Rostov State Medical University (Study Medicine in Russia). Old &amp; Reputed Medical University of Russia, Ranked 248 in Russia &amp; 5344 world rank. (As per Indian embassy listed in top 20)" />
<link rel="canonical" href="https://rostgmu.net/" />
      </Helmet>    
	  <div>
    <div itemtype="https://schema.org/Product" itemscope>
      <meta itemprop="mpn" content="925872" />
      <meta itemprop="name" content="study mbbs in russia" />
      <link itemprop="image" href="https://rostgmu.net/images/logo.webp" />
      <link itemprop="image" href="https://rostgmu.net/images/logo.webp" />
      <link itemprop="image" href="https://rostgmu.net/images/logo.webp" />
      <meta itemprop="description" content="Study MBBS in Russia, MBBS in Russia - Rostov State Medical University (Study Medicine in Russia). Old &amp; Reputed Medical University of Russia, Ranked 248 in Russia &amp; 5344 world rank. (As per Indian embassy listed in top 20)" />
      <div itemprop="offers" itemtype="https://schema.org/Offer" itemscope>
        <link itemprop="url" href="https://rostgmu.net/" />
          </div>
      <div itemprop="aggregateRating" itemtype="https://schema.org/AggregateRating" itemscope>
        <meta itemprop="reviewCount" content="113" />
        <meta itemprop="ratingValue" content="5.0" />
      </div>
      <div itemprop="review" itemtype="https://schema.org/Review" itemscope>
        <div itemprop="author" itemtype="https://schema.org/Person" itemscope>
          <meta itemprop="name" content="Rostgmu.net" />
        </div>
        <div itemprop="reviewRating" itemtype="https://schema.org/Rating" itemscope>
          <meta itemprop="ratingValue" content="5" />
          <meta itemprop="bestRating" content="5" />
        </div>
      </div>
      <meta itemprop="sku" content="0446310786" />
      <div itemprop="brand" itemtype="https://schema.org/Brand" itemscope>
        <meta itemprop="name" content="Rostgmu.net" />
      </div>
    </div>
  </div>

              <div id="slider">
			 <Sliders />
              </div>
              <div className="border-bottom-1"></div>

              <div className="row-fluid services-links-row">
		
              <div className="row">
				<div className="columns small-12">
					<div className="row large-collapse services-links">
          <div className="columns small-12 large-4">
							<a href={process.env.PUBLIC_URL + '/rostov-state-medical-university'}>
								<div className="services-links__column bg-blue">
									<i className="icon-building"></i>
                  <div className="services-links__item">
										<h2 className="services-links__title text-white">About University</h2>
										
									</div>								
								</div>
							</a>
						</div>

            <div className="columns small-12 large-4">
							<a href={process.env.PUBLIC_URL + '/welcome-message-to-foreign-students'}>
								<div className="services-links__column bg-blue">
								    <i className="icon-users-3"></i>
									<div className="services-links__item">
										<h2 className="services-links__title text-white">Vice-Chancellor's</h2>
									</div>
								</div>
							</a>
						</div>
            <div className="columns small-12 large-4">
							<a href={process.env.PUBLIC_URL + '/why-rostsmu'}>
								<div className="services-links__column bg-blue">
									<i className="icon-doc-text-inv"></i>
									<div className="services-links__item">
										<h2 className="services-links__title text-white">Why RostSMU?</h2>
									</div>
								</div>
							</a>
						</div>
          </div>
          </div>
          </div>
            </div>


            <div className="row">
			<div className="column">
<h2 className="banner-title">"Welcome to fulfill your dreams to become a successful doctor, one and only<br /> <span>best medical university of Russia &ndash; RostSMU"</span></h2>
			</div>
		</div>
		
	
		<div className="row our-people-wrapper">
			<div className="small-12 large-9 column">
				<div className="row">
					<div className="column">
						<h1 className="block-title">ROSTOV STATE MEDICAL UNIVERSITY, RUSSIA <br /> Study MBBS in Russia</h1>
					</div>
				</div>
				<div className="row">
					
					<div className="small-12 medium-12 column bg-1">
						<article className="post post--type-quote">
							<div className="post__excerpt">
                            <img src="images/1000483160.png" className="img-fluid" alt="ROSTOV STATE MEDICAL UNIVERSITY, RUSSIA | Study MBBS in Russia" />
                            <p>&nbsp;</p>
<p>Rostov State Medical University embraces a special mission to train practitioners from different countries of the world and to contribute to the improvements of health education, research, and prevention for the local population, choosing the university means plentiful opportunities for gaining professional expression and carrier pursuit.</p> 

<p>The Rostov State Medical University is accredited by the Russian Health Ministry for education for the teaching of both Russian and overseas students.</p>

<p>"We welcome you to our modernized university which will be running its courses in English/Russian Medium and will fulfill your dreams of becoming a successful doctor. Advanced knowledge through teaching, learning, and research. We are delighted that you are interested in Rostov State Medical University and look forward to receiving  your completed application."</p>

<p><a href={process.env.PUBLIC_URL + '/about-us'} className="team-person__link">Read more</a></p>
								
								
							</div>
						</article>					
					</div>
				</div>
			</div>
			
			
			<div className="small-12 large-3 column">
				<div className="row">
					<div className="small-12 medium-12 column bg-1">
						
												<div className="team-person txcenter">
							<div className="team-person__img effect-apollo">
								<h3 className="block-title">Chancellor's message</h3>
								<img src="images/Olesya.jpeg" alt="ROSTOV STATE MEDICAL UNIVERSITY, RUSSIA | Study MBBS in Russia" className="imgwd50" />
								<div className="effect-apollo__overlay"></div>
							</div>
							<h5 className="team-person__name">Acting Rector of University</h5>
							<p className="team-person__position">Candidate of Medical Sciences, Surgeon<br /> Olesya Borisovna Starzhinskaya</p>
							
							<p><a href={process.env.PUBLIC_URL + '/chancellors-message'} className="team-person__link">Read more</a></p>
						</div>
					</div>
					
				</div>
			</div>
		</div>

		<NewBlock/>
        
        <div className="row latest-posts-wrapper align-center pb-100">
        <div className="small-12 medium-12 large-12 column list-style-1">
		
        <ul>
      <li>1. RostSMU is the 7th oldest Medical Government University in Russia.</li>
        <li>2. RostSMU ranks 3rd among all medical universities in Russia (in terms of the number of budget places)</li>
       <li> 3. The Rostov State Medical University has been training specialists for foreign countries since 1961.</li>
        <li>4. Since 2005, the Rostov State Medical University has got an opportunity to teach foreign students in the English Language.</li>
       <li>5. About 10 thousand Doctors of the South of Russia, receive Postgraduate Education at RostSMU every year.</li>
        <li>6. Rank 1 Government Medical University of Southern Russia as per 2021.</li>
        <li>7. Training foreign students on the Postgraduate stage at the University has been carrying out since 1965.</li>
        <li>8. Graduates of the Rostov State Medical University have all the necessary knowledge, skills and qualifications that enable them to succeed in their future careers.</li>
        <li>9. Many foreign graduates of the Rostov State Medical University after completing the Residency and Postgraduate course became prominent statesmen, head of clinics, hospitals, departments of Ministries of Health in their Motherland.</li>
        <li>10. Rostov State Medical University is one of the largest medical research and educational centers in the Russian Federation and Eastern Europe.</li>
        </ul>      
		</div>
         </div>

         <div className="row latest-posts-wrapper align-center pb-100">
         <div className="small-12 medium-8 large-8 column">
         <section className="widget widget-latest-posts-thumb greybg">
					<h3 className="widget-title">News &amp; Upcoming Events <span class="fright"><a href="#">More..</a></span></h3>
				<Upcoming />
				</section>
			</div>

      <div className="small-12 medium-4 large-4 column">
				<section className="widget widget-latest-posts-thumb greybg">
					<h3 className="widget-title">Facebook Page <span className="fright"><a href="#">More..</a></span></h3>
          <Facebook />
          </section>
           </div>
         </div>
         <div className="row">
			<div className="small-12 medium-6 large-4 columns tile-col">
				<section className="tile effect-bubba">
					<div className="tile__icon icon-pencil"></div>
					<h3 className="tile__title">Admission Procedure</h3>
					<p className="tile__description">Rostov state medical university admission: One intake in a year for all faculties, i.e  June 20 to October 10. (For Russian Medium Faculties only from June 20 - July 22)</p>
					<i className="tile__arrow icon-right-4"></i>
					<a href={process.env.PUBLIC_URL + '/admission-procedure-for-foreign-applicants'} className="tile__link"></a>
				</section>
			</div>
			<div className="small-12 medium-6 large-4 columns tile-col">
				<section className="tile effect-bubba">
					<div className="tile__icon icon-calendar"></div>
					<h3 className="tile__title">Accommodation Student Life</h3>
					<p className="tile__description">There are 5 hostels, which can accommodate more than 2000 students. All the hostels are flat type i.e. with attached bathroom, toilet, and kitchen. The hostels are centrally heated.</p>
					<i className="tile__arrow icon-right-4"></i>
					<a href={process.env.PUBLIC_URL + '/rostsmu-hostels-for-foreign-students'} className="tile__link"></a>
				</section>
			</div>
			<div className="small-12 medium-6 large-4 columns tile-col">
				<section className="tile effect-bubba">
					<div className="tile__icon icon-users"></div>
					<h3 className="tile__title">Services to foreign students</h3>
					<p className="tile__description">RostSMU International students representative will provide to students the following documents according to the rules & regulations of the university:</p>
					<i className="tile__arrow icon-right-4"></i>
					<a href={process.env.PUBLIC_URL + '/rostsmu-services-to-foreign-students'} className="tile__link"></a>
				</section>
			</div>
		</div>

		<div className="row pb-300 align-items-center">
			<div className="columns small-12 medium-12 text-center">
		    <Youtube />
				</div>
				

				</div>
				<div className='bg-dark-red'>
				<div className="row pb-300">
				<div className="columns small-12 medium-12 text-center">
					<h3>«Время учиться быть профессионалом»<br /> "Its a Time to be a Professional"</h3>
				<Clients />
				</div>
				</div>
				</div>
				<a href='https://www.youtube.com/@rostsmuofficials' target='_blank'><img src="images/y1.jpg" className="img-fluid" alt="Rostov State Medical University - Official Channel"  /></a>
            </div>
 )
  }

}

export default Home;
