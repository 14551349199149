import React, { Component } from 'react';
import { Header } from '../layout/Header';
import { Helmet } from "react-helmet";
export class Videos extends Component {
    render() {

        return (       
            <div className="site-main">  
             <Helmet>
          <title>Videos for Rostov State Medical University</title>
          <meta name="description" content="International students wishing to study at RostSMU have to undergo the admission procedure, which is quite simple. After submitting the application form our official representative will contact you." />
          <link rel="canonical" href="https://rostgmu.net/admission-procedure-for-foreign-applicants/" />
        </Helmet>
            
<section className="hero-section overlay bg-cover">
  <div className="container">
    <div className="hero-slider">   
      <div className="hero-slider-item">
        <div className="row">
          <div className="col-md-8">
            <h1 className="text-white" data-animation-out="fadeOutRight" data-delay-out="5" data-duration-in=".3" data-animation-in="fadeInLeft" data-delay-in=".1">Videos</h1>
           
        </div>
      </div>
     
    </div>
  </div>
  </div>
</section>

<section className="section">
  <div className="container">
    <div className="row list-items">


    <div class="col-sm-4 iframe"><iframe width="100%" height="100%" src="https://www.youtube.com/embed/CniULD4Jt0I?si=05cn-0fG_euulFsI" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe></div>

<div class="col-sm-4 iframe"><iframe width="100%" height="100%" src="https://www.youtube.com/embed/Jmn7eQNEInE?si=4gjyl-EHA1ahhz5z" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe></div>

<div class="col-sm-4 iframe"><iframe width="100%" height="100%" src="https://www.youtube.com/embed/Nm-Iox9uDdE?si=eWkgxARmHTaMCDER" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe></div>

<div class="col-sm-4 iframe"><iframe width="100%" height="100%" src="https://www.youtube.com/embed/0_osKboyOYU?si=VUp6humTu7Qfn46w" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe></div>

<div class="col-sm-4 iframe"><iframe width="100%" height="100%" src="https://www.youtube.com/embed/fJalZPB-BkQ?si=q6QiDBAh_nGN5ZQQ" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe></div>

<div class="col-sm-4 iframe"><iframe width="100%" height="100%" src="https://www.youtube.com/embed/v8tiR_kbzfA?si=3l7Lnr3ITpEOjTlr" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe></div>

<div class="col-sm-4 iframe"><iframe width="100%" height="100%" src="https://www.youtube.com/embed/IMdQCSKXsYs?si=18zZSTV3svPLE9KC" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe></div>

<div class="col-sm-4 iframe"><iframe width="100%" height="100%" src="https://www.youtube.com/embed/JPD2hDI3AQY?si=5mgkW-AeCONGZ1vv" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe></div>

<div class="col-sm-4 iframe"><iframe width="100%" height="100%" src="https://www.youtube.com/embed/lKSPZij2Woc?si=DPhTJLC8F3PtNy7L" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe></div>

     
       </div>
  </div>

      </section>


  </div>


 )
}

}

export default Videos;