import React, { Component } from 'react'

export class Topheader extends Component {
	render() {

		return (
			<div className="row align-right">
				<div className="columns">
					<div className="socials socials--slide-hover">
						<a href="mailto:contact@rostgmu.net"><i className="icon-mail-alt"></i> <span>contact@rostgmu.net</span></a>
						<a href="tel:+79515173719" target='_blank'><i className="icon-phone-3"></i> <span>+79515173719</span></a>
						<a className="fright" href="#"><span><div id="google_translate_element"></div></span></a>
						<a className="fright" href="#" target="_blank"> <span>Downloads</span></a>
						<a className="fright" href="/gallery"><span>Gallery</span></a>
						<a className="fright" href="/videos"><span>Videos</span></a>
					</div>
				</div>
			</div>

		)
	}
}
export default Topheader;