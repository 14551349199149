import React, { Component }  from "react";

// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { Autoplay } from 'swiper';

import 'swiper/swiper.min.css';
import 'swiper/swiper-bundle.css';

export class Clients extends Component {

    
    render() {
        SwiperCore.use([Autoplay]);
        return (
            <>
             <Swiper
            autoplay={{
                delay: 2500,
                disableOnInteraction: false,
              }}
            spaceBetween={50}
            slidesPerView={4}            
            onSwiper={(swiper) => console.log(swiper)}
          >
           
           <SwiperSlide><img src="images/c1.webp" className="img-fluid border-image"  alt="" /></SwiperSlide>
           <SwiperSlide><img src="images/c2.webp" className="img-fluid border-image"   alt="" /></SwiperSlide>
           <SwiperSlide><img src="images/c3.webp" className="img-fluid border-image"   alt="" /></SwiperSlide>
           <SwiperSlide><img src="images/c4.webp" className="img-fluid border-image"  alt="" /></SwiperSlide>
           <SwiperSlide><img src="images/c5.webp"  className="img-fluid border-image"  alt="" /></SwiperSlide>
           <SwiperSlide><img src="images/c6.webp"  className="img-fluid border-image"  alt="" /></SwiperSlide>
           <SwiperSlide><img src="images/c7.webp"  className="img-fluid border-image"  alt="" /></SwiperSlide>
           <SwiperSlide><img src="images/c8.webp"  className="img-fluid border-image"  alt="" /></SwiperSlide>
           <SwiperSlide><img src="images/c9.webp"  className="img-fluid border-image"  alt="" /></SwiperSlide>
           <SwiperSlide><img src="images/c10.webp"  className="img-fluid border-image"  alt="" /></SwiperSlide>
           <SwiperSlide><img src="images/c11.webp"  className="img-fluid border-image"  alt="" /></SwiperSlide>
           <SwiperSlide><img src="images/c12.webp"  className="img-fluid border-image"  alt="" /></SwiperSlide>
           <SwiperSlide><img src="images/c13.webp"  className="img-fluid border-image"  alt="" /></SwiperSlide>
           <SwiperSlide><img src="images/c14.webp"  className="img-fluid border-image"  alt="" /></SwiperSlide>
           <SwiperSlide><img src="images/c15.webp"  className="img-fluid border-image"  alt="" /></SwiperSlide>

</Swiper>

      </>
           
            )
        }
    }
export default Clients;