import React, { Component } from 'react';
import { Header } from '../layout/Header';
import { Helmet } from "react-helmet";
export class Rostsmu_services_to_foreign_students extends Component {
    render() {

        return (       
            <div className="site-main">  
             <Helmet>
<title>Services to foreign students - Rostov State Medical University</title>
<meta name="description" content="Rostov state medical university foreign students representative provides best services to foreign students during admission procedure, documentations, arrival at Rostov-on-Don etc. Get direct admission through authorized representative." />
<link rel="canonical" href="https://rostgmu.net/rostsmu-services-to-foreign-students/" />
     </Helmet>  
    
<section className="hero-section overlay bg-cover">
  <div className="container">
    <div className="hero-slider">   
      <div className="hero-slider-item">
        <div className="row">
          <div className="col-md-8">
            <h1 className="text-white" data-animation-out="fadeOutRight" data-delay-out="5" data-duration-in=".3" data-animation-in="fadeInLeft" data-delay-in=".1">Services to foreign students
</h1>
           
        </div>
      </div>
     
    </div>
  </div>
  </div>
</section>

<section className="section">
  <div className="container">
    <div className="row list-items">        
      <div className="col-md-12 order-2 order-md-1">

      <h2><strong>RostSMU International students representative will provide to students the following documents according to the rules &amp; regulations of the university:</strong></h2>
<ul>
  <li>Admission letter from the Rostov State Medical University.</li>
  <li>Copy of student’s invitation letter.</li>
  <li>Transportation from Moscow airport to Rostov-on-Don or receive students at Platov International Airport Rostov-on-Don.</li>
  <li>Meal on arrival.</li>
  <li>A five-minute telephone call to parents on arrival.</li>
</ul>
<h3><strong>Services Provided by RSMU International students representative: </strong></h3>
<ul>
  <li>All relevant information regarding application procedure and admission procedure.</li>
  <li>Application form for admission to Rostov State Medical University.</li>
  <li>To receive fulfilled application forms and required documents and select candidates on basis of the previous school certificates (the terms and conditions of admission are dictated by the Rostov State Medical University).</li>
  <li>To enroll a selected candidate in the Rostov State Medical University.</li>
  <li>To provide an admission letter from the Rostov State Medical University.</li>
  <li>To receive from the Rostov State Medical University invitation letter for student’s entry visa to Russia.</li>
  <li>Help in fulfilling the requirements for applying for a visa.</li>
  <li>Help in acquiring a Russian entry visa for students.</li>
  <li>To guide the candidates in obtaining foreign currency.</li>
  <li>To provide the candidate with a list of required items needed for their stay in Russia.</li>
  <li>To guide the candidates in obtaining emigration clearance.</li>
  <li>To provide information explaining what to do at airports.</li>
  <li>Explain to the candidate the main demands of Russian laws, rules for the students of higher educational institutions, rules of residence, and traveling along with the territory of Russia.</li>
  <li>To have an official representative at the Platov Internation Airport Rostov-on-Don or at Moscow airport to advise candidates from Moscow to Rostov-on-Don.</li>
</ul>
<h3><strong>On the first arrival of the candidates to Moscow or at Rostov-on-Don:</strong></h3>
<ul>
  <li>To receive the candidate at the Moscow or Rostov Airport.</li>
  <li>To provide transport from the airport to the Rostov State Medical University.</li>
  <li>To provide the meal and furnished accommodation in the hostel after arrival.</li>
  <li>To provide telephone calls to parents.</li>
  <li>To provide registration in the local department of external affairs.</li>
  <li>To arrange health care for a student and to organize medical insurance for students for the whole period of their stay in Russia, the cost of which will be borne by the student.</li>
</ul>

      </div>
       </div>
       
      

       </div>
      </section>


  </div>


 )
}

}

export default Rostsmu_services_to_foreign_students;