import React, { Component } from 'react';
import { Header } from '../layout/Header';
import { Helmet } from "react-helmet";
export class Welcome_message_to_foreign_students extends Component {
    render() {

        return (       
            <div className="site-main">  
             <Helmet>
 <title>Vice-Chancellor’s welcome message- Rostov State Medical University</title>
<meta name="description" content="The Rostov State Medical University is the largest basic training and research center in Southern Russia. Welcome to RostSMU." />
<link rel="canonical" href="https://rostgmu.net/welcome-message-to-foreign-students/" />
      </Helmet>   
<section className="hero-section overlay bg-cover">
  <div className="container">
    <div className="hero-slider">   
      <div className="hero-slider-item">
        <div className="row">
          <div className="col-md-8">
            <h1 className="text-white" data-animation-out="fadeOutRight" data-delay-out="5" data-duration-in=".3" data-animation-in="fadeInLeft" data-delay-in=".1">Vice-Chancellor’s welcome
</h1>
           
        </div>
      </div>
     
    </div>
  </div>
  </div>
</section>


<section className="section">
  <div className="container">


    <div className="row list-items">        
      <div className="col-md-12 order-2 order-md-1">
      <img src='images/ab2.webp' className='img-fluid' alt='' />
      <p><strong>R</strong>ostov State Medical University is one of the best medical universities in Russia. It is the main medical university in Rostov-on-Don city and provides doctors and nurses for the region. Rostov Medical University is justifiably an International Medical University. Hundreds of students from thirty-two countries all around the world are studying here and some of them the learning language is English. Also, we have a large number of students who came from other regions of the Russian Federation. We are proud that Rostov State Medical University is attractive for young people from different parts of the world and our priority is always to be supportive and careful to foreign students.</p>
<p><strong>E</strong>ducation in Rostov state medical university is highly rated by local and foreign students, we are in the top twenty in the list of medical universities in the Russian Federation. Students consider RostSMU as a desirable place to study and acknowledge our high-grade teaching.</p>
<p>&rdquo;I wish you every success in your study in RostSMU and hope that it will be an exciting, challenging, and diverse place to achieve your dream to become a successful doctor.&rdquo;</p>

<div className='text-right'>
<h3><strong>Gyuzal Shamilevna Gafiyatullina</strong><br />
<strong>Acting Vice-Rector for Training of Foreign Citizens and <br /> International Cooperation of University, </strong><strong>Doctor of Medical Sciences, Professor, <br /> Head of the Department of Normal Physiology</strong></h3>
</div>

      </div>
       </div>
       
      


       </div>
      </section>


  </div>

 )
}

}

export default Welcome_message_to_foreign_students;