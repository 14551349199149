import React, { Component } from 'react'

export class Upcoming extends Component {
    render() {

        return (

            <ul>
                <marquee height="200" scrollamount="2" direction="up" onMouseOver="stop()" onMouseOut="start();">
                    <li><div className="widget-latest-posts-thumb__item-meta"><h4>The 2025 Batch Fresher's Party held in the heart of the city<br /> Rostov-on-Don - RostSMU. .<a href="https://youtu.be/vnIvcWUemV0" target="_blank">View More</a>. <img src='images/new.webp' alt="ROSTOV STATE MEDICAL UNIVERSITY, RUSSIA | Study MBBS in Russia" /></h4></div></li>
                    <li><div className="widget-latest-posts-thumb__item-meta"><h4>Rostov State Medical University entered the sixth edition of the Moscow International Ranking "Three Missions of the University".<a href={process.env.PUBLIC_URL + '/upcoming-news-rostov'}>Read More</a>. <img src='images/new.webp' alt="ROSTOV STATE MEDICAL UNIVERSITY, RUSSIA | Study MBBS in Russia" /></h4></div></li>


                    <li><div className="widget-latest-posts-thumb__item-meta"><h4>Rostov State Medical University hosted "Initiation into students" <a href={process.env.PUBLIC_URL + '/upcoming-news'}>Read More</a>. <img src='images/new.webp' alt="ROSTOV STATE MEDICAL UNIVERSITY, RUSSIA | Study MBBS in Russia" /></h4></div></li>


                    <li><div className="widget-latest-posts-thumb__item-meta"><h4>Dear aspirants, Admissions are open for 2025 academic  year. <a href={process.env.PUBLIC_URL + '#/apply-online-for-study-medicine-in-rostov-state-medical-university-russia'}>Click here to register now</a>. <img src='images/new.webp' alt="ROSTOV STATE MEDICAL UNIVERSITY, RUSSIA | Study MBBS in Russia" /></h4></div></li>
                    <li><div className="widget-latest-posts-thumb__item-meta"><h4>On 26.01.2025 Indian students have celebrated 73rd Republic day of India.<br /><a href="https://youtu.be/5Xji_AdvMsg" target="_blank">Click here to Watch Video</a>. <img src='images/new.webp' alt="Republic Day Celebration 26.01.2025 | Rostov State Medical University | City" /></h4></div></li>
                    <li><div className="widget-latest-posts-thumb__item-meta"><h4>Celebration of Diwali - The Indian Festival in RostSMU hostels.<a href="https://youtu.be/hhz-FNFrkmg" target="_blank">Click here to Watch Video</a>. <img src='images/new.webp' alt="Diwali Celebration in Hostel by Indian Students | Rostov State Medical University | Southern Russia" /></h4></div></li>
                    <li><div className="widget-latest-posts-thumb__item-meta"><h4>Simulation Training Center of Rostov State Medical University in main campus.<br /><a href="https://youtu.be/5xOkN8rak_w" target="_blank">Click here to Watch Video</a>. <img src='images/new.webp' alt="Simulation Training Center | Rostov State Medical University | Top Rank University of South Russia" /></h4></div></li>
                    <li><div className="widget-latest-posts-thumb__item-meta"><h4>Anatomy Museum of RostSMU, know the history of Department.<br /><a href="https://youtu.be/ikRiJEd5ESk" target="_blank">Click here to Watch Video</a>. <img src='images/new.webp' alt="Rostov State Medical University | Anatomy Department | MBBS In Russia" /></h4></div></li>
                    <li><div className="widget-latest-posts-thumb__item-meta"><h4>Largest library in Medical University of Southern Russia - RostSMU.<br /><a href="https://youtu.be/SJAKv9HBIWU" target="_blank">Click here to Watch Video</a>. <img src='images/new.webp' alt="Rostov State Medical University Library | One of the Largest Medical Libraries in Southern Russia" /></h4></div></li>


                </marquee>
            </ul>


        )
    }
}
export default Upcoming;