import React, { Component } from 'react';
import { Header } from '../layout/Header';
import { Helmet } from "react-helmet";
export class Vice_chancellors_message extends Component {
    render() {

        return (       
            <div className="site-main">  
             <Helmet>
<title>Vice-Chancellor&#039;s message - Rostov State Medical University, Russia</title>
<meta name="description" content="Graduates of the Rostov State Medical University have all the necessary knowledge, skills and qualifications that enable them to succeed in their future careers." />
<link rel="canonical" href="https://rostgmu.net/vice-chancellors-message/" />
      </Helmet>  
<section className="hero-section overlay bg-cover">
  <div className="container">
    <div className="hero-slider">   
      <div className="hero-slider-item">
        <div className="row">
          <div className="col-md-8">
            <h1 className="text-white" data-animation-out="fadeOutRight" data-delay-out="5" data-duration-in=".3" data-animation-in="fadeInLeft" data-delay-in=".1">Vice-Chancellor’s message</h1>
           
        </div>
      </div>
     
    </div>
  </div>
  </div>
</section>


<section className="section">
  <div className="container">

 
  <div className="row list-items align-items-center">        
      <div className="col-md-5 order-2 order-md-1">
<div className='bg-green text-center'>
      <h3>Vice-Chancellor’s message</h3>
      <img src="images/vice.webp" className="img-fluid" alt="Vice-Chancellor’s message"  />
		  
      <h3><strong>Gyuzal Shamilevna Gafiyatullina</strong></h3>
<h6><strong>Acting Vice-Rector for Training of Foreign Citizens and<br /> International Cooperation of University, </strong><br/><strong>Doctor of Medical Sciences, Professor, <br /> Head of the Department of Normal Physiology</strong></h6>
	  </div>   
          </div>
          <div className="col-md-7 order-2 order-md-1">


	
	
	 <p><strong>Dear students,</strong></p>
<p><strong>Welcome to Rostov State Medical University!</strong></p>
<p>International cooperation is an important aspect of Rostov State Medical University’s activity. Its goal is an integration into the international scientific and educational sphere, ensuring the quality of education and its compliance with international standards.</p>
<p><strong>International cooperation is carried out by the following structural units of the RSMU:</strong></p>
<ul>
<li>Dean’s Office of Preparatory Faculty.</li>
<li>Dean’s Office of Faculty of Training Foreign Students and Postgraduates</li>
<li>International Cooperation Department</li>
</ul>
<p>The realization of international educational projects includes the collaboration with the major foreign universities, scientific and medical institutions, and pharmaceutical companies in order to develop the specific research subjects, provide clinical tests of drugs and participate in international forums held in Russia and abroad. Rostov State Medical University provides reception of foreign delegations and conducts students exchange for practical training. University staff is involved in the work of international companies and organizations and does its best to get the grants of international funds and organizations.</p>
<p>An essential component of the international cooperation of the RSMU is to interest foreign students in training, based on general and special programs, to organize student exchanges and practical training for post-graduates and professors.</p>
<p>Our University is rich with knowledge and experience that is generously transferred to our students, to those whose mission will be to face the challenges of the 21st century in the field of medicine and people aiding, to implement new methods and technologies in healthcare.</p>

<div className='text-right'>
<h3><strong>Gyuzal Shamilevna Gafiyatullina</strong></h3>
<h6><strong>Acting Vice-Rector for Training of Foreign Citizens and<br /> International Cooperation of University, </strong><strong>Doctor of Medical Sciences, Professor, <br /> Head of the Department of Normal Physiology</strong></h6>
</div>

    
</div>
 </div>

    
      


       </div>
      </section>


  </div>

 )
}

}

export default Vice_chancellors_message;