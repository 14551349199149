import React, { Component } from 'react';
import { Header } from '../layout/Header';
import { Helmet } from "react-helmet";
export class Rostsmu_hostels_for_foreign_students extends Component {
    render() {

        return (       
            <div className="site-main">  
             <Helmet>
<title>Rostov state medical university, Russia - Hostels for foreign students</title>
<meta name="description" content="The Rostov state medical university has well constructed student hostels to accommodate 2500 students where both Russian and Foreign students are living." />
<link rel="canonical" href="https://rostgmu.net/rostsmu-hostels-for-foreign-students/" />
    </Helmet>  
  
<section className="hero-section overlay bg-cover">
  <div className="container">
    <div className="hero-slider">   
      <div className="hero-slider-item">
        <div className="row">
          <div className="col-md-8">
            <h1 className="text-white" data-animation-out="fadeOutRight" data-delay-out="5" data-duration-in=".3" data-animation-in="fadeInLeft" data-delay-in=".1">Accommodation-student life
</h1>
           
        </div>
      </div>
     
    </div>
  </div>
  </div>
</section>

<section className="section">
  <div className="container">
    <div className="row list-items">        
      <div className="col-md-12 order-2 order-md-1">
      <img src='images/rostsmu-hostels-for-foreign-students.webp' className='img-fluid' alt='' />
      <h2>RSMU have five hostels for all Students</h2>
<p>There are five hostels, which can accommodate more than 2000 students. All the hostels are in good condition and having kitchen, bathroom, toilet at each floor. The hostels are centrally heated and fully furnished. Separate arrangements for boys and girls (3-4 seated big rooms, upgraded comforts, central heating full furniture with beddings, shower room, toilet, washbasin, security guards, reception, laundry, cleaning and washing personnel, library in hostel, etc.).</p>
<h3>Best hostels in RostSMU as compare to other  Russian medical universities </h3>
<p>The rules and regulations for residing in these hostels are very strict and given below here:-</p>
<p>The following rules and regulations for residing in the hostel of the Rostov State Medical University have been made and approved under the signature and stamp of the Rector of RSMU. The following rules and regulations for residing in the hostel of RSMU are made for all foreign students:-</p>
<h3>I. GENERAL REGULATIONS:</h3>
<ul>
  <li>Those students, who took admission must live in the hostel provided by RSMU.</li>
  <li>RSMU administration has the right to shift a student to any other room as per its own decision.</li>
  <li>RSMU representatives have the right to check-up the rooms and other premises to keep up the discipline.</li>
  <li>All things that are allotted to the student for his room and for his personal use are allotted on the student's responsibility and the student has to sign an abiding note about it.</li>
  
  <li>Anyone, who is not a resident of the hostel, will be allowed to enter the hostel only with the permission of the hostel administration. Entering the hostel, a visitor should submit his passport or any other identity document for registering in the visitor's book. Those, who invite the visitors, are responsible for their leaving in time and keeping the rules of the hostel regulations by the visitors.</li>
  <li>If a student is expelled (debarred) from RSMU or has graduated from RSMU, he should vacate his room within 7 days of issuing the order.</li>
  <li>Any cultural or mass activity can be held in the hostel with the permission of the hostel administration only.</li>
  <li>Birthday parties can be held with the permission of the hostel administration.</li>
</ul>
<h3>II.  RIGHTS OF THOSE WHO LIVE IN THE HOSTEL:</h3>
<ul>
  <li>The students have the right to live in the hostel on the condition of obeying these rules.</li>
  <li>Those, who live in the hostel, have the right to use things for individual and general use belonging to the hostel with the permission of the hostel administration.</li>
  <li>Those, who live in the hostel, have the right to demand repair or replacement of the equipment, furniture, bedding, or other hostel belongings.</li>
</ul>
<h3>III. DUTIES OF THOSE WHO LIVE IN THE HOSTEL MUST:</h3>
<ul>
  <li>Submit the key to the room to the Warden of the Hostel if a student goes out of town for more than 10 days. The Warden of the Hostel is responsible for the safekeeping of the student's things;</li>
  <li>get aquatinted with the rules of the hostel regulations;</li>
  <li>get aquatinted with the rules of vacating the hostel;</li>
  <li>take the instructions regarding how to use the electrical equipment, video, and audio equipment;</li>
  <li>take the instructions regarding fire safety;</li>
  <li>keep the rules of the hostel regulations, safety arrangements, and fire safety;</li>
  <li>treat the hostel's property carefully;</li>
  <li>in case any damage is done to the hostel by the student, then the student has to compensate for the damage done;</li>
  <li>not waste electricity, gas, and water;</li>
  <li>inform the Warden of the hostel of all the problems regarding water-supply, energy supply, and the heating system immediately;</li>
  <li>be in the hostel not later than at 11 p.m. If a student wants to leave the hostel after 11 p.m. whatever the reason may be, he must get the RSMU administration's permission;</li>
  <li>keep the rooms clean and tidy, clean the rooms every day;</li>
  <li>if the lock in the room is replaced, one key should be given to the Warden of the Hostel;</li>
  <li>If a student leaves the hostel for holidays or due to any other reason, the student must get the hostel administration's permission not later than 2 days before;</li>
  <li>If a student vacates the hostel due to any reason, he must return given things to the Warden of the hostel.</li>
</ul>
<h3>THOSE WHO LIVE IN THE HOSTEL MUST NOT:</h3>
<ul>
  <li>smoke or keep cigarettes and tobacco items;</li>
  <li>consume alcoholic drinks or keep them;</li>
  <li>fight with one another;</li>
  <li>steal;</li>
  <li>change his room without permission;</li>
  <li>carry the things from one room to another without permission;</li>
  <li>misuse the room;</li>
  <li>let anyone stay in his room, who is not permitted to live in that room;</li>
  <li>change or repair the electrical wiring;</li>
  <li>use microwaves ovens, room heaters, etc. as per fire safety;</li>
  <li>leave the garbage near the doors of the rooms, on stairs, and in passages;</li>
  <li>sing, make noise, or use the radio and TV sets with the volume exceeding the limits of his room, before 11 p.m;</li>
  <li>sing, make noise, or use the radio and TV sets after 11 p.m;</li>
  <li>stick advertisements, timetables, pictures on the walls and places for general use without prior permission of the hostel administration;</li>
  <li>hang out advertisements in foreign languages without officially attested translation and without prior permission of the hostel administration;</li>
  <li>give a key to a room to strangers or visitors without official permission Administration of the Hostel;</li>
  <li>keep domestic animals in the room.</li>
</ul>
<h4>IF A STUDENT BREAKS THESE RULES, ONE OF THE FOLLOWING ACTIONS CAN BE TAKEN AGAINST THE STUDENT:</h4>
<ul>
  <li>WARNING.</li>
  <li>ROOM CHANGE.</li>
  <li>DEBARRING (Expel) FROM RSMU.</li>
</ul>



      </div>
       </div>

       <div className="row list-items align-items-center">   

       <div className="col-md-12 order-2 order-md-1 red">
         <h3>Welcome to Study in Russia: Most Reputed University of Russia; Rostov State Medical University</h3>
         <p><strong>Get Direct MBBS Admission in Rostov State Medical University (Russian Federation)</strong></p>
</div>

      <div className="col-md-6 order-2 order-md-1">
     <p>
     <iframe width="100%" height="315" src="https://www.youtube.com/embed/Nm-Iox9uDdE?si=lFGgdVQwXWoubuP6" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
 
 </p>
          </div>
          <div className="col-md-6 order-2 order-md-1">

          <p>
          <iframe width="100%" height="315" src="https://www.youtube.com/embed/QJFAvx52298?si=l4x-0UH-lCr8Zy4p" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe> 
            </p>

</div>
       
  

</div>
</div>
      </section>

  </div>


 )
}

}

export default Rostsmu_hostels_for_foreign_students;