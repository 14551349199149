import React, { Component } from "react";

// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { Autoplay } from 'swiper';

import 'swiper/swiper.min.css';
import 'swiper/swiper-bundle.css';

export class Sliders extends Component {


    render() {
        SwiperCore.use([Autoplay]);
        return (
            <>
             <Swiper
             autoplay={{
                delay: 2500,
                disableOnInteraction: false,
              }}
      spaceBetween={50}
      slidesPerView={1}
      onSwiper={(swiper) => console.log(swiper)}
    >
                 <SwiperSlide><img src="images/slide-1.webp" className="img-fluid" width="100%" alt="" /></SwiperSlide>
                 <SwiperSlide><img src="images/slide-2.webp" className="img-fluid" width="100%" alt="" /></SwiperSlide>
                 <SwiperSlide><img src="images/slide-3.webp" className="img-fluid" width="100%" alt="" /></SwiperSlide>
                 <SwiperSlide> <img src="images/slide-4.webp" className="img-fluid" width="100%" alt="" /></SwiperSlide>
                 <SwiperSlide><img src="images/slide-5.webp" className="img-fluid" width="100%" alt="" /></SwiperSlide>


                    </Swiper>
            </>

        )
    }
}
export default Sliders;