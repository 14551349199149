import React, { Component } from 'react';
import { Header } from '../layout/Header';
import { Helmet } from "react-helmet";
export class Gallery extends Component {
    render() {

        return (       
            <div className="site-main">  
             <Helmet>
          <title>Gallery for Rostov State Medical University</title>
          <meta name="description" content="International students wishing to study at RostSMU have to undergo the admission procedure, which is quite simple. After submitting the application form our official representative will contact you." />
          <link rel="canonical" href="https://rostgmu.net/admission-procedure-for-foreign-applicants/" />
        </Helmet>
            
<section className="hero-section overlay bg-cover">
  <div className="container">
    <div className="hero-slider">   
      <div className="hero-slider-item">
        <div className="row">
          <div className="col-md-8">
            <h1 className="text-white" data-animation-out="fadeOutRight" data-delay-out="5" data-duration-in=".3" data-animation-in="fadeInLeft" data-delay-in=".1">Gallery</h1>
           
        </div>
      </div>
     
    </div>
  </div>
  </div>
</section>

<section className="section">
  <div className="container">
    <div className="row list-items">

        <div className='col-sm-3'>
        <a href="images/g/1.jfif" data-lightbox="photo1" data-title="Gallery - Rostov State Medical University"><img src="images/g/1.jfif" className="img-fluid" alt="Gallery for Rostov State Medical University"  /></a>
        </div>

        <div className='col-sm-3'>
        <a href="images/g/2.jfif" data-lightbox="photo1" data-title="Gallery - Rostov State Medical University"><img src="images/g/2.jfif" className="img-fluid" alt="Gallery for Rostov State Medical University"  /></a>
        </div>
 <div className='col-sm-3'>
        <a href="images/g/3.jfif" data-lightbox="photo1" data-title="Gallery - Rostov State Medical University"><img src="images/g/3.jfif" className="img-fluid" alt="Gallery for Rostov State Medical University"  /></a>
        </div>
 <div className='col-sm-3'>
        <a href="images/g/5.jfif" data-lightbox="photo1" data-title="Gallery - Rostov State Medical University"><img src="images/g/5.jfif" className="img-fluid" alt="Gallery for Rostov State Medical University"  /></a>
        </div>
 <div className='col-sm-3'>
        <a href="images/g/6.jfif" data-lightbox="photo1" data-title="Gallery - Rostov State Medical University"><img src="images/g/6.jfif" className="img-fluid" alt="Gallery for Rostov State Medical University"  /></a>
        </div>
 <div className='col-sm-3'>
        <a href="images/g/7.jfif" data-lightbox="photo1" data-title="Gallery - Rostov State Medical University"><img src="images/g/7.jfif" className="img-fluid" alt="Gallery for Rostov State Medical University"  /></a>
        </div>
 <div className='col-sm-3'>
        <a href="images/g/8.jfif" data-lightbox="photo1" data-title="Gallery - Rostov State Medical University"><img src="images/g/8.jfif" className="img-fluid" alt="Gallery for Rostov State Medical University"  /></a>
        </div>
 <div className='col-sm-3'>
        <a href="images/g/9.jfif" data-lightbox="photo1" data-title="Gallery - Rostov State Medical University"><img src="images/g/9.jfif" className="img-fluid" alt="Gallery for Rostov State Medical University"  /></a>
        </div>
 <div className='col-sm-3'>
        <a href="images/g/10.jfif" data-lightbox="photo1" data-title="Gallery - Rostov State Medical University"><img src="images/g/10.jfif" className="img-fluid" alt="Gallery for Rostov State Medical University"  /></a>
        </div>
 <div className='col-sm-3'>
        <a href="images/g/11.jfif" data-lightbox="photo1" data-title="Gallery - Rostov State Medical University"><img src="images/g/11.jfif" className="img-fluid" alt="Gallery for Rostov State Medical University"  /></a>
        </div>
 <div className='col-sm-3'>
        <a href="images/g/12.jfif" data-lightbox="photo1" data-title="Gallery - Rostov State Medical University"><img src="images/g/12.jfif" className="img-fluid" alt="Gallery for Rostov State Medical University"  /></a>
        </div>

        <div className='col-sm-3'>
        <a href="images/g/13.jfif" data-lightbox="photo1" data-title="Gallery - Rostov State Medical University"><img src="images/g/13.jfif" className="img-fluid" alt="Gallery for Rostov State Medical University"  /></a>
        </div>
 <div className='col-sm-3'>
        <a href="images/g/14.jfif" data-lightbox="photo1" data-title="Gallery - Rostov State Medical University"><img src="images/g/14.jfif" className="img-fluid" alt="Gallery for Rostov State Medical University"  /></a>
        </div>


 <div className='col-sm-3'>
        <a href="images/g/17.jfif" data-lightbox="photo1" data-title="Gallery - Rostov State Medical University"><img src="images/g/17.jfif" className="img-fluid" alt="Gallery for Rostov State Medical University"  /></a>
        </div>

 <div className='col-sm-3'>
        <a href="images/g/20.jfif" data-lightbox="photo1" data-title="Gallery - Rostov State Medical University"><img src="images/g/20.jfif" className="img-fluid" alt="Gallery for Rostov State Medical University"  /></a>
        </div>
 <div className='col-sm-3'>
        <a href="images/g/21.jfif" data-lightbox="photo1" data-title="Gallery - Rostov State Medical University"><img src="images/g/21.jfif" className="img-fluid" alt="Gallery for Rostov State Medical University"  /></a>
        </div>

     
       </div>
  </div>

      </section>


  </div>


 )
}

}

export default Gallery;