import React, { Component } from 'react';
import { Header } from '../layout/Header';
import { Helmet } from "react-helmet";
export class Why_rostsmu extends Component {
    render() {

        return (       
            <div className="site-main">  
             <Helmet>
<title>study MBBS in abroad, Why to join - Rostov State Medical University Russia's Top University</title>
<meta name="description" content="The Rostov state medical university. RostSMU is a prominent University in the field of Medicine in South Russia. Rostov state medical university is one of the oldest and reputed medical university of Russia." />
<link rel="canonical" href="https://rostgmu.net/why-rostsmu/" />
      </Helmet>  
     
<section className="hero-section overlay bg-cover">
  <div className="container">
    <div className="hero-slider">   
      <div className="hero-slider-item">
        <div className="row">
          <div className="col-md-8">
            <h1 className="text-white" data-animation-out="fadeOutRight" data-delay-out="5" data-duration-in=".3" data-animation-in="fadeInLeft" data-delay-in=".1">Why RostSMU?</h1>
           
        </div>
      </div>
     
    </div>
  </div>
  </div>
</section>

<section className="section">
  <div className="container">
    <div className="row list-items">        
      <div className="col-md-12 order-2 order-md-1">
      <img src='images/w1.webp' className='img-fluid' alt='' />
      <p><strong>RostSMU</strong> is the oldest Medical University in Russia after Moscow, St. Petersburg. The only university in Rostov-on-Don which is recognized by the Ministry of Health &amp; Ministry of Education and science of the Russian Federation. RostSMU is the government medical university of the Russian Federation. RostSMU, the only university in Rostov-on-Don recognized by the WHO, listed in the World Directory of Medical Schools. RostSMU is recognized by leading Medical Councils viz. National Medical Commission of India, BM&amp;DC, PM&amp;DC, GMC, ECFMG, MDCN, etc. (several medical councils/associations of Asia, Africa, Arab, European countries). RostSMU is listed in the top twenty Medical Universities in Russia. (<strong>Rostov State Medical University</strong>).</p>


      </div>
       </div>
       


       </div>
      </section>


  </div>


 )
}

}

export default Why_rostsmu;