import React, { Component } from 'react';
import { Header } from '../layout/Header';
import { Helmet } from "react-helmet";



export class Registration_open extends Component {
    render() {

        return (
            <div className="site-main">

                <section className="hero-section overlay bg-cover">
                    <div className="container">
                        <div className="hero-slider">
                            <div className="hero-slider-item">
                                <div className="row">
                                    <div className="col-md-8">
                                        <h1 className="text-white" data-animation-out="fadeOutRight" data-delay-out="5" data-duration-in=".3" data-animation-in="fadeInLeft" data-delay-in=".1">Registration form for 2025 - 2026
                                        </h1>

                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </section>

                <section className="section">
                    <div className="container">
                        <div className="row list-items">
                            <div className="col-md-12 order-2 order-md-1 bg-light-blue">

                                <div className="spacing-10 ttm-bgcolor-grey ttm-bg ttm-col-bgcolor-yes ttm-right-span">
                                    <div className="ttm-col-wrapper-bg-layer ttm-bg-layer">
                                        <form className="row ttm-quote-form clearfix" name="f1" method="post" id="f1" action="https://app.todesign.in/react/rostgmu.net/registration.php">
                                            <div className="col-sm-6 col-md-6">
                                                <div className="form-group">
                                                    <label>First Name</label>
                                                    <input name="name" id="name" type="text" className="form-control bg-white" placeholder="Your Name*" required="required" />
                                                </div>
                                            </div>
                                            <div className="col-sm-6 col-md-6">
                                                <div className="form-group">
                                                    <label>Country</label>
                                                    <input name="country" id="country" type="text" placeholder="Country" required="required" className="form-control bg-white" />
                                                </div>
                                            </div>
                                            <div className="col-sm-6 col-md-6">
                                                <div className="form-group">
                                                    <label>WhatsApp Number</label>
                                                    <input name="phone" id="phone" type="text" placeholder="WhatsApp Number*" required="required" className="form-control bg-white" />
                                                </div>
                                            </div>
                                            <div className="col-sm-6 col-md-6">
                                                <div className="form-group">
                                                    <label>Email Address</label>
                                                    <input name="email" id="email" type="email" placeholder="Email Address*" required="required" className="form-control bg-white" />
                                                </div>
                                            </div>

                                            <div className="col-sm-12 col-md-12">
                                                <div className="form-group">
                                                    <label>Message</label>
                                                    <textarea name="message" id="message" rows={5} placeholder="Message..." required="required" className="form-control bg-white" />
                                                </div>
                                            </div>


                                            <div className="col-md-12">
                                                <div className="text-left">
                                                    <button type="submit" id="submit" name="submit" value="Submit" className="mt-3 ttm-btn ttm-btn-size-md ttm-bgcolor-skincolor" >
                                                        Submit
                                                    </button>
                                                </div>
                                            </div>
                                        </form>


                                    </div>
                                </div>

                            </div>
                        </div>


                    </div>
                </section>

            </div>


        )
    }

}

export default Registration_open;