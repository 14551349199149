import React, { Component } from 'react';
import { Header } from '../layout/Header';
import { Helmet } from "react-helmet";
export class Thank_you extends Component {
    render() {

        return (       
            <div className="site-main">  
            
<section className="hero-section overlay bg-cover">
  <div className="container">
    <div className="hero-slider">   
      <div className="hero-slider-item">
        <div className="row">
          <div className="col-md-8">
            <h1 className="text-white" data-animation-out="fadeOutRight" data-delay-out="5" data-duration-in=".3" data-animation-in="fadeInLeft" data-delay-in=".1">Thank you</h1>
           
        </div>
      </div>
     
    </div>
  </div>
  </div>
</section>

<section className="section">
  <div className="container">
    <div className="row list-items">
      <div className="col-md-12 order-2 order-md-1">
<h2 className='text-center'>Thank You  for your message. it has been sent</h2>


      </div>
       </div>
  </div>

      </section>


  </div>


 )
}

}

export default Thank_you;