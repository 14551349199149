import React, { Component } from 'react';
import { Header } from '../layout/Header';
import { Helmet } from "react-helmet";
export class Preparatory_faculty_on_training_foreign_students extends Component {
    render() {

        return (       
            <div className="site-main">  
            <Helmet>
 <title>Preparatory Faculty on Training Foreign Students - RostSMU / РостГМУ</title>
<meta name="description" content="The Preparatory Faculty on training foreign citizens welcomes students from all over the world. The foreign students complete pre-university training at the RostSMU within a year." />
<link rel="canonical" href="https://rostgmu.net/preparatory-faculty-on-training-foreign-students/" />
      </Helmet>  
     
<section className="hero-section overlay bg-cover">
  <div className="container">
    <div className="hero-slider">   
      <div className="hero-slider-item">
        <div className="row">
          <div className="col-md-8">
            <h1 className="text-white" data-animation-out="fadeOutRight" data-delay-out="5" data-duration-in=".3" data-animation-in="fadeInLeft" data-delay-in=".1">Preparatory faculty
</h1>
           
        </div>
      </div>
     
    </div>
  </div>
  </div>
</section>

<section className="section">
  <div className="container">
    <div className="row list-items">        
      <div className="col-md-12 order-2 order-md-1">
      <h1>Learn the Russian language (Preparatory Course)<br /> from Russia’s World-Renowned Medical University;<br /> Rostov State Medical University</h1>
<h4>Studying Russian as a foreign language is becoming more and more popular all over the world. </h4>

<h2>The Preparatory Faculty welcomes foreign students from across the world:</h2>
<p><strong>R</strong>ostov State Medical University (RSMU) is an important one among other best medical universities in Russia. The Preparatory Faculty welcomes students from across the world. Our reputation is built on 35 years of experience of providing a high standard of service to our students. Rostov State Medical University has been training specialists for foreign countries since 1961. The Preparatory Faculty was established in 1966. More than 10 thousand students, representing 97 different countries have been prepared by the Preparatory Faculty since 1966. </p>
<p><strong>I</strong>n 1991 the Faculty was defined as the basic one for under-graduate course students among Preparatory Faculties of Russia. It carries out its activity on the Terms of the Rules defined for foreign citizens studying in Russia. It has Dean’s office 6 departments and more than 50 members of the teaching staff. The Faculty enjoys a well-earned reputation for both research and teaching. Instructors of the Faculty have done a great deal in carrying out teaching programs in the Russian language, Biology, Chemistry, Physics, and Math.</p>
<p><strong>T</strong>he Preparatory Faculty offers an interesting program Russian: Russian language course combined ideally reading, writing, speaking, listening, training with grammar lessons. The communicative methodology serves first of all to break language barriers. At the end of the academic year students of the Preparatory Faculty pass the final examinations on Russian (in the written and oral forms), Chemistry, Biology, Physics. The final examinations are considered the entrance examinations for the first course. Extracurricular activities include many club meetings, excursions, rehearsals, and sports practices. They are a very important part of Faculty life, a link between different nationalities. Students perform in areas of common interest. The Faculty is the center of the social life of students.</p>
<p><strong>I</strong>n 1980 Rostov State Medical Institute was awarded the Order of Peoples Friendship for the achievements in training highly qualified specialists for Russia as well as for Africa, Asia, and Latin America. Foreign students are admitted to the Preparatory Faculty on international treats and on a contract basis as well.</p>
<h3>The structure of the Preparatory Faculty contains the Dean’s office and six Departments:</h3>
<h4>RostSMU Language course students</h4>
<ul>
<li> Department of the Russian Language No.1</li>
<li> Department of the Russian Language No.2</li>
<li> Department of Biology</li>
<li> Department of Chemistry</li>
<li> Department of Physics and Mathematics</li>
<li> The basis of the Social Sciences Department</li>
</ul>
<p>The staff of the Preparatory Faculty consists of 44 teachers, among them 8 associate professors, 13 senior teachers, and 12 lecturers with Ph.D.</p>
<p><strong>After the preparatory course, the students are obtaining those necessary levels of knowledge that they will need further in order to<br /> study successfully at the first course of the university being the students.</strong></p>


      </div>
       </div>
       
       <div className="row list-items align-items-center">        
      <div className="col-md-6 order-2 order-md-1">

      <div class="bg-green text-center">
<p>&rdquo;If you want to be a doctor or a pharmacist if you wish to get your education if you desire to see Rostov-on-Don and to know the Russian language if you plan to learn our traditions and culture-you have to arrive here&rdquo;</p>
<p><strong>We are welcoming you to the Preparatory Faculty of RostSMU.</strong></p>
<p><strong>Dean of Preparatory faculty- </strong>Tropina Irina Anatolivna</p>
<p>For further information, please contact: <a href="mailto:rostsmu@gmail.com">rostsmu@gmail.com</a></p>
</div>
          </div>
          <div className="col-md-6 order-2 order-md-1">
          <div class="bg-red text-center">       
<p>Learn Russian language course in Rostov state medical university, Russia | study medicine in Russia | MBBS in Russia Rostov state medical university, Russia | Preparatory course in Rostov state medical university | 100 years the Old University of Russian Federation | Russia’s top rank university for Russian Language Course</p>
</div>
</div>

          </div>


       </div>
      </section>


  </div>


 )
}

}

export default Preparatory_faculty_on_training_foreign_students;