import React, { Component }  from "react";

// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { Autoplay } from 'swiper';

import 'swiper/swiper.min.css';
import 'swiper/swiper-bundle.css';

export class Youtube extends Component {

    
    render() {
        SwiperCore.use([Autoplay]);
        return (
            <>
             <Swiper
            autoplay={{
                delay: 2500,
                disableOnInteraction: false,
              }}
            spaceBetween={50}
            slidesPerView={4}
            onSwiper={(swiper) => console.log(swiper)}
          >

<SwiperSlide><iframe width="100%" height="100%" src="https://www.youtube.com/embed/CniULD4Jt0I?si=05cn-0fG_euulFsI" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe></SwiperSlide>

<SwiperSlide><iframe width="100%" height="100%" src="https://www.youtube.com/embed/Jmn7eQNEInE?si=4gjyl-EHA1ahhz5z" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe></SwiperSlide>

<SwiperSlide><iframe width="100%" height="100%" src="https://www.youtube.com/embed/Nm-Iox9uDdE?si=eWkgxARmHTaMCDER" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe></SwiperSlide>

<SwiperSlide><iframe width="100%" height="100%" src="https://www.youtube.com/embed/0_osKboyOYU?si=VUp6humTu7Qfn46w" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe></SwiperSlide>

<SwiperSlide><iframe width="100%" height="100%" src="https://www.youtube.com/embed/fJalZPB-BkQ?si=q6QiDBAh_nGN5ZQQ" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe></SwiperSlide>

<SwiperSlide><iframe width="100%" height="100%" src="https://www.youtube.com/embed/v8tiR_kbzfA?si=3l7Lnr3ITpEOjTlr" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe></SwiperSlide>

<SwiperSlide><iframe width="100%" height="100%" src="https://www.youtube.com/embed/IMdQCSKXsYs?si=18zZSTV3svPLE9KC" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe></SwiperSlide>

<SwiperSlide><iframe width="100%" height="100%" src="https://www.youtube.com/embed/JPD2hDI3AQY?si=5mgkW-AeCONGZ1vv" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe></SwiperSlide>

<SwiperSlide><iframe width="100%" height="100%" src="https://www.youtube.com/embed/lKSPZij2Woc?si=DPhTJLC8F3PtNy7L" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe></SwiperSlide>


</Swiper>

      </>
            )
        }
    }
export default Youtube;