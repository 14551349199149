import React, { Component } from 'react';
import Topheader from './Topheader';
import News from './News';
import { Menu } from './Menu';
import Logo from './Logo';

export class Header extends Component {
    componentDidMount(){
        window.scroll(0,0)
   }
   
    
    render() {  
              
        return (
        <header className="main-header ">
        <div className="row-fluid row-fluid--socials">
		<Topheader />
	   </div>  
       <div className="row-fluid services-links-row1">
           <News />
         </div> 

         <div className="row-fluid" id="js-menu-sticky-anchor">		
        <Logo />       
	   </div>

         <div className="sticky-container">
		<div className="row-fluid row-fluid--menu js-sticky">
		<div className="row align-middle main-navigation">
        <Menu />
        

        </div>
        </div>
        </div>


     </header>

            )
        }
    }
    
    export default Header;